import * as React from "react";
import "react-tabulator/lib/css/tabulator.min.css";
import MasterReport from "../masterReport";

const report_fields = ['booking', 'customer', 'airline', 'booking_date', 'e_ticket', 'passengers']
const report_group_by = ['customer', 'airline', 'booking_date']

const variantsConfig = {
    'Default': [[true, true, true, true, true, true], [false, false, false]],
}


const table_columns = [
    // eslint-disable-next-line no-undef
    {title: "Booking", field: "booking", width: 250, responsive: 2}, //hide this column first
    {title: "Airline", field: "airline", width: 150, responsive: 2}, //hide this column first
    {title: "Customer", field: "customer", width: 250, responsive: 2}, //hide this column first
    {title: "Passengers", field: "passengers", width: 250, responsive: 2}, //hide this column first
    {title: "E-ticket", field: "e_ticket", width: 250, responsive: 2}, //hide this column first

    // {
    //     title: "Travel credit", field: "travel_credit", width: 350, responsive: 2,
    //     formatter: "link",
    //     formatterParams: {
    //         //label: "travel_credit",
    //         labelField: "travel_credit",
    //         urlField: "booking_id_tc",
    //         urlPrefix: "/#/booking/",
    //         //target: "_blank",
    //     }
    // }, //hide this column first
    {title: "Booking date", field: "booking_date", hozAlign: "center", width: 150, formatter: "date",},
    {
        title: "Amount remaining",
        field: "amount",
        hozAlign: "right",
        width: 190,
        formatter: "money",
        topCalc: "sum",
        topCalcFormatter: "money"
    },
]

const filters = {
    // booking: ['Query', 'booking/admin/booking', (option) => `Booking ${option.id}`],
    airline: ['Query', false, 'booking/admin/airline', (option) => option.title],
    customer: ['Query', false, 'booking/admin/customer', (option) => option.name],
    booking: ['Query', false, 'booking/admin/booking', (option) => `Booking ${option.id}`],
    e_ticket: ['String', false, '',],
}


const TravelCreditV2 = () => {

    return <MasterReport
        report_fields={report_fields}
        report_group_by={report_group_by}
        variantsConfig={variantsConfig}
        table_columns={table_columns}
        report_url='api/reports/v2/travel_credits'
        filters={filters}
    />
};

export default TravelCreditV2
