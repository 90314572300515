import * as React from 'react';
import {useEffect} from "react";
import {
    TextInput,
    // useLocale,
    BooleanInput,
    ReferenceInput,
    AutocompleteInput,
    SelectInput,
    FormDataConsumer,
    useRedirect,
    RadioButtonGroupInput, ArrayInput, SimpleFormIterator, ReferenceField,
} from 'react-admin';

import {Box, Button, Grid, Typography} from "@material-ui/core";
import {DateInputComp} from "../_common/CompReact";
import {allCustomers, paymentType, paymentTypeOnlyCash, ticketClass, ticketType} from "../utils/choices";
import {validateFirstName, validateReq} from "../utils/validateTable";
import {BookingPassengerListForTab2} from "./BookingPassengerListForTab2";

import get from 'lodash/get';
import set from 'lodash/set';
import {BookingsReferenceListForTab2} from "./BookingsReferenceListForTab2";
import SaleForBooking from "./SaleForBooking";
import useMyStyles from "../utils/useMyStyles";
import SupplierForBooking from "./SupplierForBooking";
import HiddenCityForBooking2 from "./HiddenCityForBooking2";
import AirlineBooking from "./AirlineForBooking";
import moment from "moment";

import {useForm} from "react-final-form";
import TravelCreditForBooking2 from "./TravelCreditForBooking2";
import {apiPathConfig} from "../utils/config";
import {GetInvoice, GetString} from "./BookingColumnsForList";
import {fetchJson as httpClient} from "../utils/keycloak";
import BookingNonFlightTransactions from "./BookingNonFlightTransactions";

import VisibilityIcon from '@material-ui/icons/Visibility';
import CreateIcon from '@material-ui/icons/Create';
import Alert from "@material-ui/lab/Alert";

function setFormData(formData, dt) {
    /*for (const [key, value] of Object.entries(dt)) {
        console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
    }*/
    Object.entries(dt).forEach(([key, value]) => {
        //console.log(`${key} ${value}`);
        set(formData, key, value)
    });
}

const ShowUrl = (props) => {
    const {formData, classes} = props;
    const [edit, setEdit] = React.useState(formData.url ? false : true)
    //const record = useRecordContext(props);
    //let rec = get(record, source, null)
    const handleEdit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setEdit(!edit)
    }
    let rec = formData.url
        ? formData.url.replaceAll('\n', ' ').trim()
        : null
    rec = formData.url ? formData.url.split(',') : null
    return (
        <>
            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom className={classes.booking_title}>
                    URL
                    {edit
                        ? <VisibilityIcon
                            style={{color: 'blue', marginLeft: 4, cursor: "pointer"}}
                            onClick={handleEdit}
                        />
                        : <CreateIcon
                            style={{color: 'blue', marginLeft: 4, cursor: "pointer"}}
                            onClick={handleEdit}
                        />
                    }
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Box alignItems={'top'} display={'grid'}>
                    {edit
                        ?
                        <Box flex={1} mt={-1}>
                            <TextInput source="url" label={'Url'} multiline={true} fullWidth/>
                        </Box>
                        :
                        rec && rec.length > 0 &&
                        <Box
                            flex={1}
                            style={{
                                //marginTop: -24,
                                display: "grid",
                                border: '1px solid #ddd',
                                borderRadius: 4,
                                padding: 8
                            }}
                        >
                            {rec.map((it, index) => {
                                return (
                                    <div key={index}>
                                        <span>- </span>
                                        <a
                                            style={{overflowWrap: 'anywhere'}}
                                            href={it.trim()}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {it.trim()}
                                        </a>
                                    </div>
                                )
                            })
                            }
                        </Box>
                    }
                </Box>
            </Grid>
        </>

    )

}
const CopyBooking = (props) => {//dead_end_refund, refund, changing, ///booking/admin/booking/duplicate/{pk}/{action}/
    const {id, type} = props;
    const tName = {
        dead_end_refund: 'Dead-end Refund',
        refund: 'Copy To Refund',
        changing: 'Copy To Changing',
        non_flight_refund: 'Copy To Refund',
        non_flight_changing: 'Copy To Changing'
    }
    //const classes = useMyStyles();
    //const [isCopy, setIsCopy] = React.useState(false)
    const redirect = useRedirect()

    const getData = async (par) => {
        const url = apiPathConfig.getApiUrl()
        const report_url = `booking/admin/booking/duplicate/${id}/${type}/`;
        try {
            // const response = await fetch(`${url}${report_url}`);
            const response = await httpClient(`${url}${report_url}`);
            //setIsCopy(true);
            //if (response.ok) {
            if (response.status >= 200 && response.status <= 300) {
                const {new_booking_id} = response.json || {};
                /*let actualData = await response.json();
                const {new_booking_id} = actualData || {};*/
                if (new_booking_id) redirect(`/booking/${new_booking_id}/`)
            }
        } catch (err) {
            console.log('err=======>', err);
        } finally {
            console.log('finally=======>', 'finally');
        }
    }

    const handleClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        getData(type);
        //redirect(`/booking/91/`)
    };

    /*useEffect(() => {
        console.log('isCopy==============>', isCopy)
    }, [isCopy]);// eslint-disable-line*/
    return (
        <Button
            //disabled={!data[resource].passenger_id}
            style={{backgroundColor: "aliceblue"}}
            onClick={handleClick}
            variant="contained"
            //color="primary"
            //label={'Create New'}
        >
            {tName[type]}
        </Button>
    )
}

const MainBlock = (props) => {
    const {formData, customerId, supplierId, startForm, setStartForm, id} = props;
    //const classes = useMyStyles();
    const redirect = useRedirect()
    if (startForm === false) {
        setStartForm(true);
        /*localStorage.setItem('oriFdsBookingForm', JSON.stringify(formData));*/
        let res = JSON.parse(localStorage.getItem("fdsBookingForm"));
        if (
            res &&
            (!id || res.id === id)
        ) {
            // formData.update(res);
            setFormData(formData, res)
            if (customerId) set(formData, 'customer_id', parseInt(customerId))
            if (supplierId) set(formData, 'supplier_id', parseInt(supplierId))
            console.log(parseInt(customerId), 'startForm==============>', res)
        }
        localStorage.removeItem('fdsBookingForm')
    }
    let customer_id = get(formData, 'customer_id', null)

    useEffect(() => {
        if (setStartForm) {
            console.log('formData==============>', formData)
            localStorage.setItem('fdsBookingForm', JSON.stringify(formData));
        }
    }, [formData]);// eslint-disable-line
    return (
        <div style={{marginTop: -10}}>
            <ReferenceInput
                sort={{field: 'name', order: 'ASC'}}
                source="customer_id" reference="customer"
                validate={validateReq}>
                <AutocompleteInput
                    resettable
                    optionText="name"
                    defaultValue={parseInt(customer_id) ? parseInt(customer_id) : null}
                />
            </ReferenceInput>
            <Button
                //disabled={!data[resource].passenger_id}
                style={{marginTop: -10}}
                onClick={() => {
                    localStorage.setItem('fdsBookingForm', JSON.stringify(formData));
                    redirect(`/customer/create/?customerId=${customer_id}&bookingId=${id ? id : 0}`)
                }}
                color="primary"
                //label={'Create New'}
            >
                {`Create Customer`}
            </Button>
            <Button
                //disabled={!data[resource].passenger_id}
                style={{marginTop: -10}}
                onClick={() => {
                    localStorage.setItem('fdsBookingForm', JSON.stringify(formData));
                    redirect(`/customer/${customer_id}/?customerId=${customer_id}&bookingId=${id ? id : 0}`)
                }}
                color="primary"
                //label={'Create New'}
            >
                {`Edit Customer`}
            </Button>
        </div>
    )
}
export const BookingEditCreate = props => {//Не работает
    /*const configureQuill = quill => quill.getModule('toolbar').addHandler('image', function (value) {
        this.quill.format('image', value)
    });*/
    const classes = useMyStyles();
    //const redirect = useRedirect()

    // const {setValueForm} = useFormContext();
    // setValueForm('cost_ticket_tax_currency_exchange', 1);
    const formEdit = useForm();

    const {record, customerId, supplierId, type_flight} = props;
    const {id, transaction_type, invoice, is_deleted} = record;
    const {id: invoiceId} = invoice || {};
    const [startForm, setStartForm] = React.useState(false)
    const [startExchangeRate, setStartExchangeRate] = React.useState({
        amountTax: false,
        amountFee: false,
        tax: false,
        fee: false
    })
    // const locale = useLocale();

    useEffect(() => {
        const handleWheel = (event) => {
            if (document.activeElement === event.target && event.target.type === 'number') {
                event.preventDefault();
            }
        };

        window.addEventListener('wheel', handleWheel, {passive: false});

        return () => {
            window.removeEventListener('wheel', handleWheel);
        };
    }, []);


    if (!id && type_flight) formEdit.change('transaction_type', type_flight);

    let ori_customer_id = get(record, 'customer_id', '---')
    return (
        <Grid container style={{width: '100%', marginTop: 8}} spacing={2}>
            {is_deleted &&
                <Grid item xs={12}>
                    <Alert severity="error" style={{color: "red", textAlign: "center"}}>
                        <h3 style={{margin: 0}}>Attention !!! This entry is marked as deleted. If you want, you can
                            restore it.</h3>
                        <BooleanInput
                            style={{marginBottom: -32}}
                            source="is_deleted" label={'Is deleted'}
                            defaultValue={false}
                        />
                    </Alert>
                </Grid>
            }
            <Grid item xs={12}>
                <Grid container alignItems={"flex-start"} justifyContent={"space-between"} spacing={2}>
                    <Grid item>
                        <Typography variant="h6" gutterBottom>FLIGHT TICKET / CHANGE</Typography>
                        <Typography
                            variant="h6"
                            //gutterBottom
                            style={{
                                fontSize: 18,
                                marginTop: -10,
                                color: "cornflowerblue",
                                fontWeight: "bold"
                            }}
                        >
                            <div style={{display: "grid"}}>
                                <span>{type_flight === 'non_flight' ? type_flight : transaction_type}</span>
                                {transaction_type !== "booking" && transaction_type !== "non_flight" &&
                                    <ReferenceField source="transaction_id" reference="booking"
                                                    label={'Issued airline'}>
                                        <div style={{fontSize: 14, marginTop: -4, cursor: "pointer"}}>
                                            <span>For </span>
                                            <GetString source="id" title={'route'}/>{' / '}
                                            <GetString source="travel_date" title={'route'}/>{' / '}
                                            <GetString source="route" title={'route'}/>{' / $'}
                                            <GetString source="sale_total_dollar" title={'route'}/>
                                        </div>
                                    </ReferenceField>
                                }
                            </div>

                        </Typography>
                        <FormDataConsumer>
                            {({formData, ...rest}) => {
                                return (
                                    <div>
                                        <BooleanInput
                                            style={{color: formData.canceled && 'red'}}
                                            source="canceled" label={'Canceled'}
                                            defaultValue={false}/>
                                    </div>
                                )
                            }}
                        </FormDataConsumer>
                    </Grid>
                    {id && //transaction_type !== "non_flight" &&
                        <>
                            <Grid item>
                                <CopyBooking
                                    id={id}
                                    type={transaction_type.indexOf('non_flight')>=0 ? 'non_flight_changing' : 'changing'}
                                />
                            </Grid>
                            {/*<Grid item>
                                <CopyBooking id={id} type={'dead_end_refund'}/>
                            </Grid>*/}
                            <Grid item>
                                <CopyBooking
                                    id={id}
                                    type={transaction_type.indexOf('non_flight')>=0 ? 'non_flight_refund' : 'refund'}
                                />
                            </Grid>
                        </>
                    }
                    {/*<Grid item  style={{marginTop: -10}}>
                        <ReferenceInput source="project_id" reference="projects" label={'Progect'} validate={validateReq}>
                                    <AutocompleteInput resettable optionText="name" fullWidth/>
                                </ReferenceInput>
                    </Grid>*/}
                    <Grid item><FormDataConsumer>
                        {({formData, ...rest}) => {
                            return (
                                <MainBlock
                                    id={id}
                                    formData={formData}
                                    customerId={customerId}
                                    supplierId={supplierId}
                                    startForm={startForm}
                                    setStartForm={setStartForm}
                                    {...rest}
                                />)
                        }}
                    </FormDataConsumer>

                    </Grid>
                </Grid>
            </Grid>

            {/*<Grid item xs={12}>
                <Grid container alignItems={"center"} spacing={2}>
                    <Grid item xs={4}>
                                <GetInvoice source={'invoice'} form={true} style={{fontSize: 24}}/>
                            </Grid>
                </Grid>
            </Grid>*/}
            <Grid item xs={12}>
                <Grid container alignItems={"center"} spacing={2}>
                    <Grid item xs={3}>
                        <DateInputComp source={"booking_date"}
                                       time={true}
                                       label={"Booking date"} validate={validateReq}
                                       defaultValue={moment(new Date()).format()}
                                       fullWidth
                        />
                    </Grid>
                    {/*<Grid item xs={3}>
                        <div style={{display: "inline-flex"}}>
                            <span style={{padding: '8px 16px'}}>Cash/Card</span>
                            <BooleanInput source="miles_only" label={'Miles only'} defaultValue={false}/>
                        </div>
                    </Grid>*/}
                    {invoiceId
                        ?
                        <Grid item xs={4} style={{marginTop: -24}}>
                            <GetInvoice source={'invoice'} form={true}/>
                        </Grid>
                        :
                        <Grid item xs={4}>
                            <BooleanInput source="is_paid" label={'Is paid'} defaultValue={false}/>
                        </Grid>
                    }
                    {/*<Grid item xs={3} style={{marginTop: -24}}>
                        <BooleanInput source="is_load" label={'Exported to Xero'} defaultValue={false}/>
                    </Grid>*/}
                    <Grid item xs={5} style={{marginTop: -8}}>
                        <RadioButtonGroupInput
                            source="payment_type"
                            choices={(type_flight || (transaction_type && transaction_type.indexOf('non_flight')>=0)) ? paymentTypeOnlyCash : paymentType}
                            label={false}
                            validate={validateReq} defaultValue={'cash'}
                        />
                    </Grid>
                </Grid>
            </Grid>
            {/*<Grid item xs={12} style={{marginTop: -38}}>
                <Grid container alignItems={"center"} spacing={2}>
                    <Grid item xs={3}>
                        <TextInput
                            size={"small"}
                            variant={"outlined"}
                            fullWidth
                            source={"booking_id_external"}
                            label={"Booking external"}
                        />
                    </Grid>
                    <Grid item xs={4}/>
                    <Grid item xs={5}/>
                </Grid>
            </Grid>*/}

            {id
                ?
                <>
                    <FormDataConsumer>
                        {({formData, ...rest}) => {
                            // debugger
                            let payment_type = get(formData, 'payment_type', null)
                            if (payment_type === 'miles_only') return null;

                            let customer_id = get(formData, 'customer_id', null)
                            return (
                                <>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" gutterBottom className={classes.booking_title}>
                                            Passengers
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{marginBottom: -48}}>
                                        <RadioButtonGroupInput source="tmp_all_customers" choices={allCustomers}
                                                               defaultValue={false}
                                                               label={' '}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container alignItems={"center"} spacing={2}>
                                            <Grid item xs={12}>
                                                <Box>
                                                    {id && ori_customer_id
                                                        ?
                                                        <Box display="grid" alignItems={"center"}>
                                                            <Box flex={1} fullWidth={false}>
                                                                <BookingPassengerListForTab2
                                                                    resource={'bookings_passenger'}
                                                                    // basePath={`/booking/${id}/2`}
                                                                    basePath={`/bookings_passenger`}
                                                                    booking_id={id}
                                                                    customer_id={customer_id}
                                                                    formData={formData}
                                                                />
                                                            </Box>
                                                        </Box>
                                                        :
                                                        <Typography variant="subtitle1" style={{color: "red"}}>
                                                            To select a passenger, fill in and save the "Customer"
                                                            field
                                                        </Typography>
                                                    }
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                            )
                        }}
                    </FormDataConsumer>

                    {transaction_type.indexOf('non_flight')<0 && //transaction_type !== "non_flight" &&
                        <FormDataConsumer>
                            {({formData, ...rest}) => {
                                // debugger
                                let payment_type = get(formData, 'payment_type', null)
                                if (payment_type === 'miles_only') return null;
                                return (
                                    <>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" gutterBottom className={classes.booking_title}>
                                                PNR
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container alignItems={"center"} spacing={2}>
                                                <Grid item xs={12}>
                                                    <Box>
                                                        {id
                                                            ?
                                                            <>
                                                                <Box display="grid" alignItems={"center"}>
                                                                    <Box flex={1} fullWidth={false}>
                                                                        <BookingsReferenceListForTab2
                                                                            {...props}
                                                                            resource={'bookings_reference'}
                                                                            // basePath={`/booking/${id}/2`}
                                                                            basePath={`/bookings_reference`}
                                                                            booking_id={id}
                                                                            formData={formData}
                                                                        />
                                                                    </Box>
                                                                </Box>
                                                                <Box display="grid" alignItems={"center"}>
                                                                    <Box flex={1} fullWidth={false}>
                                                                        <AirlineBooking {...props}/>
                                                                    </Box>
                                                                </Box>
                                                            </>
                                                            :
                                                            <Typography variant="subtitle1" style={{color: "red"}}>
                                                                To add a PNR, fill in and save the "Customer"
                                                                field
                                                            </Typography>
                                                        }
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                )
                            }}
                        </FormDataConsumer>
                    }

                    {transaction_type.indexOf('non_flight')<0 && //transaction_type !== "non_flight" &&
                        <FormDataConsumer>
                            {({formData, ...rest}) => {
                                // debugger
                                let payment_type = get(formData, 'payment_type', null)
                                if (payment_type === 'miles_only') return null;
                                let ticket_type = get(formData, 'ticket_type', null)
                                let legs = get(formData, 'legs', null)
                                if (legs === null || legs.length === 0)
                                    formEdit.change('legs', [
                                        {travel_date: null, from_place: '', to_place: '', route: '', flight_number: ''},
                                        {travel_date: null, from_place: '', to_place: '', route: '', flight_number: ''}
                                    ]);
                                else if (legs.length === 1)
                                    formEdit.change('legs', [
                                        legs[0],
                                        {travel_date: null, from_place: '', to_place: '', route: '', flight_number: ''}
                                    ]);
                                return (
                                    <>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" gutterBottom className={classes.booking_title}>
                                                Ticket
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container alignItems={"center"} spacing={2}>
                                                <Grid item xs={3}>
                                                    <SelectInput source="ticket_type" choices={ticketType}
                                                                 validate={validateReq}
                                                                 label={'Ticket type'}
                                                                 fullWidth/>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <ReferenceInput source="issued_airline_id" reference="airline"
                                                                    validate={validateReq}
                                                                    label={'Issued airline'}
                                                                    sort={{field: 'title', order: 'ASC'}}
                                                    >
                                                        <AutocompleteInput resettable
                                                                           optionText={record => (record && `${record.title} (${record.code})`)}
                                                                           fullWidth/>
                                                    </ReferenceInput>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <ReferenceInput source="operated_airline_id" reference="airline"
                                                                    validate={validateReq}
                                                                    label={'Operated airline'}
                                                                    sort={{field: 'title', order: 'ASC'}}
                                                    >
                                                        <AutocompleteInput resettable
                                                                           optionText={record => (record && `${record.title} (${record.code}) `)}
                                                                           fullWidth/>
                                                    </ReferenceInput>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <SelectInput source="ticket_class" choices={ticketClass}
                                                                 validate={validateReq}
                                                                 label={'Ticket class'}
                                                                 fullWidth/>
                                                </Grid>

                                                {/*<Grid item xs={3}>
                                                    <TextInput source="from_place"
                                                               validate={validateReq}
                                                               label={'From place'}
                                                               fullWidth/>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextInput source="to_place"
                                                               validate={validateReq}
                                                               label={'To Place'}
                                                               fullWidth/>
                                                </Grid>*/}
                                                {/*<Grid item xs={12}>
                                                    <TextInput source="route" label={'Route'}
                                                               validate={validateFirstName}
                                                               fullWidth/>
                                                </Grid>*/}
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container alignItems={"center"} spacing={2}>
                                                {ticket_type === 'single'
                                                    ?
                                                    <>
                                                        <Grid item xs={3}>
                                                            <DateInputComp source={"travel_date"} label={"Travel date"}
                                                                           validate={validateReq}
                                                                           fullWidth/>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextInput source="from_place"
                                                                       validate={validateReq}
                                                                       label={'From place'}
                                                                       fullWidth/>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextInput source="to_place"
                                                                       validate={validateReq}
                                                                       label={'To Place'}
                                                                       fullWidth/>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextInput source="flight_number"
                                                                       validate={validateFirstName}
                                                                       label={'Flight Number'}
                                                                       fullWidth/>
                                                        </Grid>
                                                    </>
                                                    : ticket_type === 'rtn'
                                                        ?
                                                        <>
                                                            <Grid item xs={2}>
                                                                <DateInputComp source={"travel_date"}
                                                                               label={"From Date"}
                                                                               validate={validateReq}
                                                                               fullWidth/>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <DateInputComp source={"travel_date_return"}
                                                                               label={"To Date"}
                                                                               validate={validateReq}
                                                                               fullWidth/>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <TextInput source="from_place"
                                                                           validate={validateReq}
                                                                           label={'From place'}
                                                                           fullWidth/>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <TextInput source="to_place"
                                                                           validate={validateReq}
                                                                           label={'To Place'}
                                                                           fullWidth/>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <TextInput source="flight_number"
                                                                           validate={validateFirstName}
                                                                           label={'Outbound Flight No.'}
                                                                           fullWidth/>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <TextInput source="flight_number_return"
                                                                           validate={validateFirstName}
                                                                           label={'Return Flight Number'}
                                                                           fullWidth/>
                                                            </Grid>
                                                        </>
                                                        : null
                                                }
                                                {(ticket_type === 'multi_city') &&
                                                    <Grid item xs={12} style={{marginTop: -36}}>
                                                        <ArrayInput source="legs"
                                                                    label={'Add Travel date, Route, Flight Number for Multi City'}>
                                                            <SimpleFormIterator
                                                                //addButton={<Button style={{color: 'blue'}}>Add Route, Flight Number and Ticket Class</Button>}
                                                                // className={classes.SimpleFormIterator}
                                                                className={"SimpleFormIterator"}
                                                                disableReordering={true}
                                                                inline={true}
                                                                fullWidth={true}
                                                                //disableAdd={ticket_type === 'rtn' && legs && legs.length > 0}
                                                                disableRemove={ticket_type === 'multi_city' && legs && legs.length < 3}
                                                            >
                                                                <DateInputComp source={"travel_date"}
                                                                               label={"Travel date"}
                                                                               validate={validateReq}
                                                                               fullWidth/>
                                                                <TextInput source="from_place" label={'From Place'}
                                                                           validate={validateFirstName} fullWidth/>
                                                                <TextInput source="to_place" label={'To Place'}
                                                                           validate={validateFirstName} fullWidth/>
                                                                <TextInput source="flight_number"
                                                                           validate={validateFirstName}
                                                                           label={'Flight Number'}
                                                                           fullWidth/>
                                                            </SimpleFormIterator>
                                                        </ArrayInput>
                                                    </Grid>
                                                }

                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12}>
                                            {id
                                                ?
                                                payment_type === 'cash' || payment_type === 'miles'
                                                    ?
                                                    <>
                                                        <Typography variant="subtitle1">Hidden City</Typography>
                                                        <HiddenCityForBooking2 {...props} formData={formData}
                                                                               booking_id={id}/>
                                                    </>
                                                    : null
                                                :
                                                <Typography variant="subtitle1" style={{color: "red"}}>
                                                    To add a Hidden City, fill in and save the "Customer"
                                                    field
                                                </Typography>
                                            }
                                        </Grid>
                                    </>
                                )
                            }}
                        </FormDataConsumer>
                    }

                    {/*<RefundForBooking2
                        {...props}
                        id={id}
                        booking_id={id}
                    />*/}
                    <FormDataConsumer>
                        {({formData, ...rest}) => {
                            let payment_type = get(formData, 'payment_type', null)
                            if (transaction_type.indexOf('non_flight')<0  //transaction_type !== "non_flight"
                                && payment_type === 'cash')
                                return (
                                    <TravelCreditForBooking2
                                        {...props}
                                        id={id}
                                        booking_id={id}
                                    />
                                )
                            else return null
                        }}
                    </FormDataConsumer>


                    {transaction_type.indexOf('non_flight')<0 && //transaction_type !== "non_flight" &&
                        <FormDataConsumer>
                            {({formData, ...rest}) => {
                                // debugger
                                // let payment_type = get(formData, 'payment_type', null)
                                // if (payment_type === 'miles_only') return null;
                                return (
                                    <SaleForBooking
                                        {...props}
                                        id={id}
                                        record={record}
                                        formData={formData}
                                        formEdit={formEdit}
                                        startExchangeRate={startExchangeRate}
                                        setStartExchangeRate={setStartExchangeRate}
                                    />
                                )
                            }}
                        </FormDataConsumer>
                    }

                    {transaction_type.indexOf('non_flight')>=0 && //transaction_type === "non_flight" &&
                        <FormDataConsumer>
                            {({formData, ...rest}) => {
                                // debugger
                                return (
                                    <Grid item xs={12}>
                                        <BookingNonFlightTransactions
                                            {...props}
                                            formData={formData}
                                            formEdit={formEdit}
                                            booking_id={id}
                                        />
                                    </Grid>
                                )
                            }}
                        </FormDataConsumer>
                    }


                    <SupplierForBooking {...props} />

                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom className={classes.booking_title}>
                            Notes
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput source="notes" label={'Notes'} multiline={true} fullWidth/>
                    </Grid>
                    <FormDataConsumer>
                        {({formData, ...rest}) => {
                            // debugger
                            return (
                                <ShowUrl source="url" formData={formData} classes={classes}/>
                            )
                        }}
                    </FormDataConsumer>
                </>
                :
                <Grid item xs={12} alignItems={'center'}>
                    <Typography variant="subtitle1" style={{color: "red", textAlign: "center"}}>For further work fill in
                        the CUSTOMER field and save</Typography>
                </Grid>
            }
        </Grid>

    )
};

export default BookingEditCreate
